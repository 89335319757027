import React from "react";
import SEO from "@src/components/SEO";
import FeatureChooserSection from "@src/components/partials/toolkit/FeatureChooserSection";
import CallToActionSection from "@src/components/partials/toolkit/CallToActionSection";
import FeatureListSection from "@src/components/partials/toolkit/FeatureListSection";

function Toolkit() {
  return (
    <>
      <SEO
        title="page_titles.toolkit.toolkit"
        description="page_descriptions.toolkit.toolkit"
        image="https://static.classdojo.com/img/page_toolkit/toolkit_banner.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Toolkit on ClassDojo",
          description: "All your favorite teacher tools, now in the ClassDojo app :)",
          image: "https://static.classdojo.com/img/page_toolkit/toolkit_banner.jpg",
        }}
      />
      <FeatureChooserSection />
      <FeatureListSection />
      <CallToActionSection />
    </>
  );
}

export default Toolkit;
